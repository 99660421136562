
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
interface passwordData {
  password: string;
  password_confirm: string;
}

@Component
export default class ResetPasswordForm extends Mixins(FormValidator) {
  private url_frontoffice = process.env.VUE_APP_FRONT_OFFICE_URL as string;
  protected formData: passwordData = {
    password: "",
    password_confirm: ""
  };

  private credentials_found = true;
  private user_email = "";
  private confirm = false;

  private mounted() {
    if (
      typeof this.$route.query.token === "undefined" ||
      this.$route.query.token.toString() === ""
    ) {
      window.open(this.url_frontoffice, "_self");
    }
  }

  private beforeMount() {
    if (
      typeof this.$route.query.token === "undefined" ||
      this.$route.query.token.toString() === ""
    ) {
      window.open(this.url_frontoffice, "_self");
    }
  }

  private resetPassword(): boolean {
    this.$axios
      .patch("personas/recuperar/contrasena/response", {
        password: this.formData.password,
        password_confirmacion: this.formData.password,
        token: this.$route.query.token
      })
      .then(response => {
        this.$router.push({ name: "login" });
        this.$q.notify({
          message: this.$t("notification.successfull_password_reset") as string,
          color: "principal",
          position: "top",
          timeout: 7500
        });
      })
      .catch(error => {
        window.open(this.url_frontoffice, "_self");
        if (
          ["expired_token", "token_no_data", "invalid_token"].includes(
            error.response.data.error.message
          )
        ) {
          this.$q.notify({
            message: "Token inválido",
            color: "principal",
            position: "top",
            timeout: 7500
          });
        } else {
          this.$q.notify({
            message: "Hubo un problema cambiando la contraseña",
            color: "principal",
            position: "top",
            timeout: 7500
          });
        }
        this.$q.notify({
          message: this.$t(
            "notification." + error.response.data.message
          ) as string,
          color: "principal",
          position: "top",
          timeout: 7500
        });
      });
    return true;
  }
}
